export const featureFlags = {
  basicTemplateOnly: "basic_template_only",
  deviceGroups: "device_groups",
  deviceSchedules: "device_schedules",
  hardcodedUrls: "hardcoded_urls",
  nutritionIntegration: "nutrition_integration",
  playlists: "playlists",
  productTours: "product_tours",
  weatherConfig: "weather_config",
};
