import * as React from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { ISO8601DateTime } from "../../helpers/types";
import dayjs from "dayjs";
import { SxProps, Theme } from "@mui/material/styles";
import { MobileDateTimePicker } from "@mui/x-date-pickers";
import { useRef } from "react";
import "dayjs/locale/en";
import "dayjs/locale/en-gb";
import "dayjs/locale/es";
import "dayjs/locale/it";
import "dayjs/locale/fr";
import "dayjs/locale/nl";
import "dayjs/locale/pt";
import "dayjs/locale/pt-br";
import "dayjs/locale/de";
import { useTranslation } from "react-i18next";

export const DateTimePicker = (props: {
  label: string;
  value: ISO8601DateTime;
  onChange: (value: ISO8601DateTime) => void;
  sx?: SxProps<Theme>;
  disablePast?: boolean;
  error?: boolean;
  helperText?: string;
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { t, i18n } = useTranslation("dateFormats");
  return (
    <LocalizationProvider
      dateAdapter={AdapterDayjs}
      adapterLocale={t("datePickerLocale")}
    >
      <MobileDateTimePicker
        sx={props.sx}
        label={props.label}
        value={dayjs(props.value)}
        onChange={(val) => {
          if (val != null) {
            props.onChange(val.toISOString());
          }
        }}
        disablePast={props.disablePast}
        orientation="landscape"
        inputRef={inputRef}
        ampm={i18n.language === "en-US"}
        slotProps={{
          textField: {
            error: props.error,
            helperText: props.helperText,
          },
        }}
      />
    </LocalizationProvider>
  );
};
