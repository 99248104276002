export const english = {
  dateFormats: {
    datePickerLocale: "en-gb",
    timeFormat: "HH:mm", // 14:30
    dayFormat: "do", // 12th
    dayMonthFormat: "do MMMM", // 1st April
    dayMonthYearFormat: "do MMMM yyyy", // 11th January 1988
    dayMonthYearTimeFormat: "do MMMM yyyy, HH:mm", // 11th January 1988, 14:30
  },
  App: {
    "Digital Displays": "Digital Displays",
    Loading: "Loading",
    "Checking authentication": "Checking authentication",
    "Redirecting to login": "Redirecting to login",
    "Sign in with Teamworks": "Sign in with Teamworks",
    Initalizing: "Initalizing",
    "Verifing authentication": "Verifing authentication",
    Redirecting: "Redirecting",
    "Welcome back!": "Welcome back!",
    "Login to get started": "Login to get started",
  },
  AppSwitcher: {
    arms: "Compliance + Recruiting",
    hub: "Hub",
    inflcr: "Influencer",
    academics: "Academics",
    communities: "Communities",
    pulse: "Pulse",
    pathways: "Pathways",
    nutrition: "Nutrition",
    ams: "AMS",
    tv: "Digital Displays",
    trac: "Inventory Management",
    strength: "Strength + Conditioning",
    wallet: "Wallet",
    gm: "General Manager",
  },
  Layout: {
    "Checking authentication": "Checking authentication",
    Dashboard: "Dashboard",
    Configurations: "Configurations",
    Devices: "Devices",
    Themes: "Themes",
    Settings: "Settings",
    Announcements: "Announcements",
    "Organisation settings": "Organisation settings",
    "Teamworks Help": "Teamworks Help",
    "Teamworks Apps": "Teamworks Apps",
    "Privacy policy": "Privacy policy",
    Logout: "Logout",
    "Loading, please wait...": "Loading, please wait...",
    Users: "Users",
    "Switch organisation": "Switch organisation",
    "Signing you out": "Signing you out",
    "Open Menu": "Open Menu",
    "Close Menu": "Close Menu",
    Playlists: "Playlists",
  },
  ConfigsScreen: {
    "{{count}} Configuration": "{{count}} Configuration",
    "{{count}} Configuration_other": "{{count}} Configurations",
    "New configuration": "New configuration",
    "Configurations allow you to customise the look and content of your displays.":
      "Configurations allow you to customise the look and content of your displays.",
    "Loading configurations": "Loading configurations",
    Configurations: "Configurations",
    Name: "Name",
    Tags: "Tags",
    Template: "Template",
    Theme: "Theme",
    "Device count": "Device count",
    "Created at": "Created at",
    "No configurations have been setup yet":
      "No configurations have been setup yet",
    "No configurations found for '{{searchText}}'":
      "No configurations found for '{{searchText}}'",
    "Displays allow you to cast scheduled events and media to any device with an internet browser":
      "Displays allow you to cast scheduled events and media to any device with an internet browser",
    "Create one now": "Create one now",
    "Create configuration": "Create configuration",
    "Add custom videos, YouTube videos and images":
      "Add custom videos, YouTube videos and images",
    "Filter calendar to present a live schedule":
      "Filter calendar to present a live schedule",
    "Display weather for multiple locations":
      "Display weather for multiple locations",
    "A general overview for up to a week in advance":
      "A general overview for up to a week in advance",
    "Show temperature, wind and probability of precipitation":
      "Show temperature, wind and probability of precipitation",
    "Cycle through important updates on cards":
      "Cycle through important updates on cards",
    "Includes team crest, time and date": "Includes team crest, time and date",
    "Display a schedule over multiple days":
      "Display a schedule over multiple days",
    "Display daily schedule for different teams and groups.":
      "Display daily schedule for different teams and groups.",
    "Configuration deleted": "Configuration deleted",
    "Are you sure you want to delete this configuration?":
      "Are you sure you want to delete this configuration?",
    "Delete configuration": "Delete configuration",
    "Fetching configuration": "Fetching configuration",
    "Digital Displays URL": "Digital Displays URL",
    "You may want to use this hardcoded url to integrate with systems that do not support our QR code setup.":
      "You may want to use this hardcoded url to integrate with systems that do not support our QR code setup.",
    "Please speak with your Teamworks administrator to ensure your IP addresses are permitted to access the link for security.":
      "Please speak with your Teamworks administrator to ensure your IP addresses are permitted to access the link for security.",
    "Something went wrong": "Something went wrong",
    "Edit configuration": "Edit configuration",
    "Get hardcoded URL": "Get hardcoded URL",
    "Includes background image from theme":
      "Includes background image from theme",
    "Last active at {{lastActiveAt}}": "Last active at {{lastActiveAt}}",
    "Loading screen devices": "Loading screen devices",
    "Search configurations...": "Search configurations...",
    "Save changes": "Save changes",
    "Last updated": "Last updated",
    "Clear template": "Clear template",
    "No template selected": "No template selected",
    "Hardcoded playlists and configurations are being depreciated, please use virtual devices.":
      "Hardcoded playlists and configurations are being depreciated, please use virtual devices.",
    "Just like hardcoded playlists and configurations, a virtual device will provide you with a URL to be used with systems which do not support the QR setup.":
      "Just like hardcoded playlists and configurations, a virtual device will provide you with a URL to be used with systems which do not support the QR setup.",
    "With a virtual device, you will be able to manage the active playlist or configuration without needing to update the third party system.":
      "With a virtual device, you will be able to manage the active playlist or configuration without needing to update the third party system.",
    "Display a full menu or an individual station":
      "Display a full menu or an individual station",
    "Optionally show images, descriptions and macros":
      "Optionally show images, descriptions and macros",
  },
  DashboardScreen: { Dashboard: "Dashboard" },
  DevicesScreen: {
    Devices: "Devices",
    Groups: "Groups",
    "{{count}} Device": "{{count}} Device",
    "{{count}} Device_other": "{{count}} Devices",
    "New device": "New device",
    "Devices are the physical displays you have linked to your account.":
      "Devices are the physical displays you have linked to your account.",
    "No devices have been setup yet": "No devices have been setup yet",
    "No devices found for '{{searchText}}'":
      "No devices found for '{{searchText}}'",
    "Device name": "Device name",
    Facility: "Facility",
    Configuration: "Configuration",
    "Facility not set": "Facility not set",
    "Config not set": "Config not set",
    "Create device": "Create device",
    "Update device": "Update device",
    "Screen device updated": "Screen device updated",
    "Something went wrong": "Something went wrong",
    "Adding new display device": "Adding new display device",
    addNewDeviceDescription:
      "To add a new device, visit <0>{{url}}</0> on the device you want to link.",
    "You will be shown a QR code which can be scanned by your camera.":
      "You will be shown a QR code which can be scanned by your camera.",
    "Start scanning": "Start scanning",
    Ok: "Ok",
    "Are you sure you want to delete this device?":
      "Are you sure you want to delete this device?",
    "Delete device": "Delete device",
    "Fetching devices": "Fetching devices",
    Timezone: "Timezone",
    "Display name is required": "Display name is required",
    "Display name must be at least 3 characters long":
      "Display name must be at least 3 characters long",
    "Device setup successful": "Device setup successful",
    "Switch organisation": "Switch organisation",
    "Search devices...": "Search devices...",
    "Device not found": "Device not found",
    "It may have been deleted, or you do not have permission to view it.":
      "It may have been deleted, or you do not have permission to view it.",
    "Loading device": "Loading device",
    "Last updated": "Last updated",
    "Playlist not set": "Playlist not set",
    "Bulk actions": "Bulk actions",
    "Edit selected": "Edit selected",
    "Delete selected": "Delete selected",
    "Delete {{count}} devices": "Delete {{count}} devices",
    "Are you sure you want to delete these devices?":
      "Are you sure you want to delete these devices?",
    "Devices deleted": "Devices deleted",
    "Screen devices updated": "Screen devices updated",
    "Update {{count}} devices": "Update {{count}} devices",
    "Device deleted": "Device deleted",
    "Select all": "Select all",
    "Deselect all": "Deselect all",
    "Create virtual device": "Create virtual device",
    "Virtual device URL": "Virtual device URL",
    "This URL can be used to integrate with systems that do not support our QR code setup.":
      "This URL can be used to integrate with systems that do not support our QR code setup.",
    "Note: This URL will only be accessable from networks with an approved IP address.":
      "Note: This URL will only be accessable from networks with an approved IP address.",
    or: "or",
    "Provides a hardcoded URL, for use with systems which do not support the QR setup":
      "Provides a hardcoded URL, for use with systems which do not support the QR setup",
    "You can now create virtual devices for use on systems which do not support the QR setup. This update replaces hardcoded playlist and configuration URL’s.":
      "You can now create virtual devices for use on systems which do not support the QR setup. This update replaces hardcoded playlist and configuration URL’s.",
    "Manage schedule": "Manage schedule",
    "Please correct any errors and try again":
      "Please correct any errors and try again",
    "Skip interaction screen": "Skip interaction screen",
    skip_interaction_description:
      "The interaction screen displays a button which must be pressed before any content is displayed. This helps prevent auto-play issues on modern browsers, but may not be required for some systems.",
  },
  DeviceModePicker: {
    "Device mode": "Device mode",
    "Should this device display a configuration or a playlist?":
      "Should this device display a configuration or a playlist?",
    "Should this device display a configuration or a playlist?_other":
      "Should these devices display a configuration or a playlist?",
    config: "Configuration",
    playlist: "Playlist",
  },
  NotFoundScreen: {
    "Oops! You seem to be lost.": "Oops! You seem to be lost.",
    "Return to dashboard": "Return to dashboard",
  },
  ThemeScreen: {
    "New theme": "New theme",
    "Themes allow you to customise the colours of your configurations.":
      "Themes allow you to customise the colours of your configurations.",
    Themes: "Themes",
    "You don't have any themes yet": "You don't have any themes yet",
    "Themes allow you to customise the colours used by your templates":
      "Themes allow you to customise the colours used by your templates",
    "Create one now": "Create one now",
    "Theme name": "Theme name",
    "Last updated": "Last updated",
    "Create theme": "Create theme",
    "Update theme": "Update theme",
    background: "Background",
    backgroundText: "Background text",
    accent: "Accent",
    accentText: "Accent text",
    announcementBar: "Announcement bar",
    announcementBarText: "Announcement bar text",
    "Invalid hex code": "Invalid hex code",
    "New theme created": "New theme created",
    "Theme updated": "Theme updated",
    "Screen theme deleted": "Screen theme deleted",
    "Something went wrong": "Something went wrong",
    "Are you sure you want to delete this theme?":
      "Are you sure you want to delete this theme?",
    "Delete theme": "Delete theme",
    "Loading themes": "Loading themes",
    "Upload logo": "Upload logo",
    "Remove logo": "Remove logo",
    "Upload background image": "Upload background image",
    "Remove image": "Remove image",
    "Theme not found": "Theme not found",
    "It may have been deleted, or you do not have permission to view it":
      "It may have been deleted, or you do not have permission to view it",
    "Loading theme": "Loading theme",
    "Search themes...": "Search themes...",
    "{{count}} Theme": "{{count}} Theme",
    "{{count}} Theme_other": "{{count}} Themes",
    "What's a theme?": "What's a theme?",
    "Themes allow you to customise the appearance of your configurations":
      "Themes allow you to customise the appearance of your configurations",
    "Clicking this button will begin the process of creating a new one.":
      "Clicking this button will begin the process of creating a new one.",
    "Give your theme a memorable name": "Give your theme a memorable name",
    "This will help you find it later when assigning it to a configuration.":
      "This will help you find it later when assigning it to a configuration.",
    "Upload a logo (optional)": "Upload a logo (optional)",
    "If you do not upload a logo, a Teamworks logo will appear in its place.":
      "If you do not upload a logo, a Teamworks logo will appear in its place.",
    "Upload a background image (optional)":
      "Upload a background image (optional)",
    "Some configurations make use of a background image. If an image is not uploaded, the background colour selected below will be used.":
      "Some configurations make use of a background image. If an image is not uploaded, the background colour selected below will be used.",
    "Customise the colours you want to use":
      "Customise the colours you want to use",
    "The 'text' options should be a contrasting colour, to ensure it can be easily read.":
      "The 'text' options should be a contrasting colour, to ensure it can be easily read.",
    "Once you are ready, save the theme by clicking this button":
      "Once you are ready, save the theme by clicking this button",
    "Your theme will then be available to be selected when managing a configuration.":
      "Your theme will then be available to be selected when managing a configuration.",
    "Theme contrast": "Theme contrast",
    "Some content appears in containers that are semi transparent and overlayed on your chosen background colour.":
      "Some content appears in containers that are semi transparent and overlayed on your chosen background colour.",
    "Would you like to lighten or darken your background?":
      "Would you like to lighten or darken your background?",
    Dark: "Dark",
    Light: "Light",
    "Theme preview": "Theme preview",
    Close: "Close",
    Preview: "Preview",
    "No themes found for '{{searchText}}'":
      "No themes found for '{{searchText}}'",
    or: "or",
    "Use organisation logo": "Use organisation logo",
  },
  ScreenTemplateNames: {
    "L shape with media": "L shape with media",
    "Media only": "Media only",
    "Schedule only": "Schedule only",
    "Multi day schedule": "Multi day schedule",
    "Multi column schedule": "Multi column schedule",
    Basic: "Basic",
    Nutrition: "Nutrition",
    Weather: "Weather",
  },
  DialogProvider: {
    Cancel: "Cancel",
    Confirm: "Confirm",
    Close: "Close",
    Ok: "Ok",
    "Close without saving?": "Close without saving?",
    "Close without saving": "Close without saving",
    "Any unsaved changes will be lost": "Any unsaved changes will be lost",
    "Back to form": "Back to form",
  },
  ConfigurationPicker: {
    Configuration: "Configuration",
    "No configurations found for '{{searchText}}'":
      "No configurations found for '{{searchText}}'",
    "No configurations found": "No configurations found",
    "No configuration selected": "No configuration selected",
    "Clear configuration": "Clear configuration",
    "Searching configurations": "Searching configurations",
  },
  ThemePicker: {
    Theme: "Theme",
    "Clear theme": "Clear theme",
    "Screen theme": "Screen theme",
    "No theme selected": "No theme selected",
    "No themes found matching '{{searchText}}'":
      "No themes found matching '{{searchText}}'",
    "No themes found": "No themes found",
    "Loading themes...": "Loading themes...",
  },
  MarkdownField: {
    Preview: "Preview",
    Remove: "Remove",
    "Nothing to preview": "Nothing to preview",
    Write: "Write",
  },
  ErrorScreen: {
    "Something went wrong": "Something went wrong",
    "Sorry for the inconvience, our engineers have been notified and will be working on a fix":
      "Sorry for the inconvience, our engineers have been notified and will be working on a fix",
    "If this issue persists, please contact Teamworks customer support":
      "If this issue persists, please contact Teamworks customer support",
    Reload: "Reload",
    "Customer support": "Customer support",
  },
  OrganisationSwitcher: {
    "Fetching organisations": "Fetching organisations",
    "Select an organisation": "Select an organisation",
    "Switching profile": "Switching profile",
  },
  TimeZonePicker: {
    Timezone: "Timezone",
  },
  MatchesDrawer: {
    "Add match": "Add match",
    "Fetching event": "Fetching event",
    "Event title": "Event title",
    "Starts at": "Starts at",
    "Create event": "Create event",
  },
  FileUploadField: {
    "Upload file": "Upload file",
    "Remove file": "Remove file",
  },
  HubProfilePicker: {
    Search: "Search",
    "Clear search text": "Clear search text",
    "Search text too short": "Search text too short",
    "Please add at least 3 characters to begin a search":
      "Please add at least 3 characters to begin a search",
    "No calendars found": "No calendars found",
    "We couldn't find any calendars matching '{{searchText}}'":
      "We couldn't find any calendars matching '{{searchText}}'",
    "Search...": "Search...",
    "Confirm selection": "Confirm selection",
    Close: "Close",
    "Add Teamworks calendars": "Add Teamworks calendars",
    "Loading profiles": "Loading profiles",
    "Search text must have at least 3 characters":
      "Search text must have at least 3 characters",
    "No results found": "No results found",
    "Calendar details": "Calendar details",
    "Remove calendar": "Remove calendar",
    "Are you sure you want to remove this calendar?":
      "Are you sure you want to remove this calendar?",
    "No calendars added": "No calendars added",
    "Unknown profile": "Unknown profile",
    "You don't have access to this hub profile":
      "You don't have access to this hub profile",
    "Your administrator may need to review your permission groups.":
      "Your administrator may need to review your permission groups.",
    "Show {{count}} more": "Show {{count}} more",
  },
  ScreenEventsPicker: {
    "Loading events": "Loading events",
    "Event countdown": "Event countdown",
    "Create event": "Create event",
    "Update event": "Update event",
    "Event title": "Event title",
    "Starts at": "Starts at",
    "Add opponent badge": "Add opponent badge",
    "Remove opponent badge": "Remove opponent badge",
    "Delete event": "Delete event",
    "Are you sure you want to delete this event?":
      "Are you sure you want to delete this event?",
    "Delete now": "Delete now",
    "Opponent badge position": "Opponent badge position",
    "Should the opponent badge be on the left or right?":
      "Should the opponent badge be on the left or right?",
    "i.e are you playing home or away?": "i.e are you playing home or away?",
    "Edit event": "Edit event",
  },
  ScreenConfigDrawer: {
    "Loading configuration": "Loading configuration",
    "Number of days the schedule should span. Max 5":
      "Number of days the schedule should span. Max 5",
    "Number of days": "Number of days",
    "Delete configuration": "Delete configuration",
    "Are you sure you want to delete this configuration?":
      "Are you sure you want to delete this configuration?",
    "Configuration deleted": "Configuration deleted",
    "Something went wrong": "Something went wrong",
    "Save changes": "Save changes",
    "Configuration updated": "Configuration updated",
    cards: "Cards",
    cards_desc: "Select cards to add text or imagery",
    media: "Media",
    media_desc: "Select from video, images and youtube content",
    multi_column_schedule: "Multi column schedule",
    multi_column_schedule_desc:
      "Select teamworks calendars to display on screen",
    multi_day_schedule: "Multi day schedule",
    multi_day_schedule_desc: "Select teamworks calendars to display on screen",
    schedule: "Schedule",
    schedule_desc: "Select teamworks calendars to display on screen",
    nutrition_menu: "Nutrition menu",
    nutrition_menu_desc: "Select the menu you want to display",
    nutrition_dining_station: "Nutrition dining station",
    nutrition_dining_station_desc:
      "Select the dining station you want to display",
    weather: "Weather",
    weather_desc: "Display weather for multiple locations",
    "%future added value": "Unknown component",
    "%future added value_desc": "Unknown component",
    "Configuration name": "Configuration name",
    "Add event countdown": "Add event countdown",
    "A countdown will be displayed until the event starts":
      "A countdown will be displayed until the event starts",
    "Not saved": "Not saved",
    "Please fix any errors and try again":
      "Please fix any errors and try again",
    "Give the configuration a name": "Give the configuration a name",
    "Try to give it a relevant name, to help you quickly find it":
      "Try to give it a relevant name, to help you quickly find it",
    "Pick a theme": "Pick a theme",
    "The theme will set colours and logo for your configuration":
      "The theme will set colours and logo for your configuration",
    "Add an event (optional)": "Add an event (optional)",
    "A countdown will be displayed on the configuration until the event starts":
      "A countdown will be displayed on the configuration until the event starts",
    "Add a Teamworks schedule": "Add a Teamworks schedule",
    "Upload media": "Upload media",
    "Add cards (optional)": "Add cards (optional)",
    "Add multiple Teamworks schedules": "Add multiple Teamworks schdules",
    "Unknown component": "Unknown component",
    "Events from the selected profiles will be shown on the configuration":
      "Events from the selected profiles will be shown on the configuration",
    "You can upload images, videos or add YouTube videos":
      "You can upload images, videos or add YouTube videos",
    "The configuration will rotate through the cards one at a time":
      "The configuration will rotate through the cards one at a time",
    "Each schedule will have its own column on the configuration":
      "Each schedule will have its own column on the configuration",
    "A multiday schedule will display the events from the selected profiles across multiple days":
      "A multiday schedule will display the events from the selected profiles across multiple days",
    "Save the configuration": "Save the configuration",
    "Once you are happy with your updates, save the changes":
      "Once you are happy with your updates, same the changes",
    "Take a tour": "Take a tour",
    "Remove component": "Remove component",
    "Are you sure you want to remove this component?":
      "Are you sure you want to remove this component?",
    "Add weather": "Add weather",
    "A weather widget will be displayed to show current weather":
      "A weather widget will be displayed to show current weather",
    "Hide past events": "Hide past events",
    "Announcements with matching tags will be displayed on screen":
      "Announcements with matching tags will be displayed on screen",
    "Display weather information for multiple locations across different days":
      "Display weather information for multiple locations across different days",
  },
  AnnouncementsScreen: {
    "Announcement text is required": "Announcement text is required",
    "Announcements updated": "Announcements updated",
    "Something went wrong": "Something went wrong",
    "Announcements allow you to post important information to be displayed in banners across all devices.":
      "Announcements allow you to post important information to be displayed in banners across all devices.",
    Announcements: "Announcements",
    "Loading announcements": "Loading announcements",
    "Manage announcements": "Manage announcements",
    "Announcements are displayed on all screens, at all times":
      "Announcements are displayed on all screens, at all times",
    "No announcements yet": "No announcements yet",
    "New announcement": "New announcement",
    "Save announcement": "Save announcement",
    "Note: announcements must be a single line. New lines will be ignored.":
      "Note: announcements must be a single line. New lines will be ignored.",
    "Delete announcement": "Delete announcement",
    "Are you sure you want to remove this annoucement?":
      "Are you sure you want to remove this annoucement?",
    "{{count}} Announcement": "{{count}} Announcement",
    "{{count}} Announcement_other": "{{count}} Announcements",
    Content: "Content",
    "Last updated": "Last updated",
    "Display mode": "Display mode",
    "Should the announcement be displayed on all playlists and configurations, or only on those with matching tags?":
      "Should the announcement be displayed on all playlists and configurations, or only on those with matching tags?",
    "Display on all": "Display on all",
    Tagged: "Tagged",
    Tags: "Tags",
    "Add start and end times": "Add start and end times",
    "Set the start and end time of the announcement":
      "Set the start and end time of the announcement",
    "Starts at": "Starts at",
    "Ends at": "Ends at",
    "End time should be after the start time":
      "End time should be after the start time",
    "End time cannot be in the past": "End time cannot be in the past",
  },
  CardsFormField: {
    "Add card": "Add card",
    "Add image": "Add image",
    "Remove image": "Remove image",
    "Remove card": "Remove card",
    "Are you sure you want to remove this card?":
      "Are you sure you want to remove this card?",
    "No cards added": "No cards added",
    "Card title": "Card title",
    "Drag and drop to re-order cards": "Drag and drop to re-order cards",
    "Move up": "Move up",
    "Move down": "Move down",
    Scheduled: "Scheduled",
    "Starts at": "Starts at",
    "Ends at": "Ends at",
  },
  MediaFormField: {
    "Invalid YouTube link, please try again":
      "Invalid YouTube link, please try again",
    "File not supported, please upload an image or video":
      "File not supported, please upload an image or video",
    "Upload image/video": "Upload image/video",
    "Add YouTube": "Add YouTube",
    "Add YouTube video": "Add YouTube video",
    "Video title": "Video title",
    "YouTube link": "YouTube link",
    Image: "Image",
    Video: "Video",
    YouTube: "YouTube",
    "Something went wrong, file not uploaded":
      "Something went wrong, file not uploaded",
    "Cancel file upload?": "Cancel file upload?",
    "Are you sure you want to cancel this upload?":
      "Are you sure you want to cancel this upload?",
    "Upload image": "Upload image",
    "Upload video": "Upload video",
    "Uploading {{uploadPct}}%": "Uploading {{uploadPct}}%",
    "Upload complete": "Upload complete",
    Name: "Name",
    "Number seconds image displays before moving to next media item":
      "Number seconds image displays before moving to next media item",
    "Duration (seconds)": "Duration (seconds)",
    "Add image": "Add image",
    "Add video": "Add video",
    "Delete media": "Delete media",
    "Are you sure you want to delete this media?":
      "Are you sure you want to delete this media?",
    "Update duration": "Update duration",
    "Edit duration": "Edit duration",
    "Remove media": "Remove media",
    "No media added": "No media added",
  },
  MultiColumnScheduleFormField: {
    "Add column": "Add column",
    "Column title": "Column title",
    "Remove column": "Remove column",
    "Are you sure you want to remove this column?":
      "Are you sure you want to remove this column?",
    "Hide past events": "Hide past events",
  },
  AuthProvider: {
    "Fetching active users": "Fetching active users",
    "Setting organisations": "Setting organisations",
    "Logging you in": "Logging you in",
  },
  AccessDeniedScreen: {
    "You don't have access to Digital Displays":
      "You don't have access to Digital Displays",
    "Please contact Teamworks customer support to request access":
      "Please contact Teamworks customer support to request access",
    "Sign out": "Sign out",
    "Customer support": "Customer support",
    Reload: "Reload",
  },
  DeviceGroupsTab: {
    "Delete group": "Delete group",
    "Are you sure you want to delete this group?":
      "Are you sure you want to delete this group?",
    "Device group deleted successfully": "Device group deleted successfully",
    "Something went wrong": "Something went wrong",
    "Device group created successfully": "Device group created successfully",
    "Group updated": "Group updated",
    "New group": "New group",
    "Device groups allow you to group your display devices under one name.":
      "Device groups allow you to group your display devices under one name.",
    "Device groups": "Device groups",
    "{{count}} Device group": "{{count}} Device group",
    "{{count}} Device group_other": "{{count}} Device groups",
    "Search groups...": "Search groups...",
    "Group name": "Group name",
    "Device count": "Device count",
    "Fetching groups": "Fetching groups",
    "No groups": "No groups",
    "Group name is required": "Group name is required",
    "Please select at least 2 devices": "Please select at least 2 devices",
    "Create group": "Create group",
    "Update group": "Update group",
    "Fetching devices...": "Fetching devices...",
    "Select devices": "Select devices",
    Devices: "Devices",
    Groups: "Groups",
    "Number devices": "Number devices",
  },
  ErrorState: {
    "Something went wrong": "Something went wrong",
    "Please try again, or contact Teamworks support if the issue persists":
      "Please try again, or contact Teamworks support if the issue persists",
  },
  ProductToursProvider: {
    Back: "Back",
    Next: "Next",
    Finish: "Finish",
  },
  Drawer: {
    Close: "Close",
  },
  InitialSearchParamsProvider: {
    "Initializing URL params": "Initializing URL params",
  },
  PreviewsProvider: {
    "Redirecting to preview": "Redirecting to preview",
  },
  PlaylistsScreen: {
    Playlists: "Playlists",
    "New playlist": "New playlist",
    "{{count}} Playlist": "{{count}} Playlist",
    "{{count}} Playlist_other": "{{count}} Playlists",
    Name: "Name",
    Tags: "Tags",
    "Loading playlists": "Loading playlists",
    "No playlists have been setup yet": "No playlists have been setup yet",
    "Playlists allow you to join multiple configurations together.":
      "Playlists allow you to join multiple configurations together.",
    "No configurations selected": "No configurations selected",
    "Add configuration": "Add configuration",
    Cancel: "Cancel",
    "Create playlist": "Create playlist",
    "Delete playlist": "Delete playlist",
    "Are you sure you want to delete this playlist?":
      "Are you sure you want to delete this playlist?",
    "Loading playlist": "Loading playlist",
    "Save changes": "Save changes",
    "Configuration already in playlist": "Configuration already in playlist",
    "The selected configuration is already part of this playlist, do you want to add it again?":
      "The selected configuration is already part of this playlist, do you want to add it again?",
    "Remove from playlist": "Remove from playlist",
    Configuration: "Configuration",
    "Number loops": "Number loops",
    Active: "Active",
    "Playlist updated": "Playlist updated",
    "Something went wrong": "Something went wrong",
    "Last updated": "Last updated",
    "Digital Displays URL": "Digital Displays URL",
    "You may want to use this hardcoded url to integrate with systems that do not support our QR code setup.":
      "You may want to use this hardcoded url to integrate with systems that do not support our QR code setup.",
    "Get hardcoded URL": "Get hardcoded URL",
    l_layout_loop_description:
      "Media items will loop a given number of times before changing to the next configuration",
    media_only_loop_description:
      "Media items will loop a given number of times before changing to the next configuration",
    basic_loop_description:
      "Schedule will loop a given number of times before changing to the next configuration",
    multi_column_schedule_loop_description:
      "Schedule will loop a given number of times before changing to the next configuration",
    multi_day_schedule_loop_description:
      "Schedule will loop a given number of times before changing to the next configuration",
    schedule_only_loop_description:
      "Schedule will loop a given number of times before changing to the next configuration",
    "%future added value_loop_description": "Unknown configuration type",
    mvp_loop_description: "Unknown configuration type",
    nutrition_loop_description:
      "Menu or dining station will loop a given number of times before changing to the next configuration",
    weather_loop_description: "Display weather for multiple locations",
    timed_description:
      "Configuration will stay on screen for a given amount of time before changing to the next configuration",
    Looped: "Looped",
    Timed: "Timed",
    "Duration (minutes)": "Duration (minutes)",
    "Search playlists...": "Search playlists...",
    "Edit configuration": "Edit configuration",
    "New configuration": "New configuration",
    "Loading configuration": "Loading configuration",
    "Add an existing configuration to the playlist":
      "Add an existing configuration to the playlist",
    "Create a new configuration to add to the playlist":
      "Create a new configuration to add to the playlist",
    "No playlists found for '{{searchText}}'":
      "No playlists found for '{{searchText}}'",
    "Last active at {{lastActiveAt}}": "Last active at {{lastActiveAt}}",
    "Device count": "Device count",
    "Loading screen devices": "Loading screen devices",
    "Announcements with matching tags will be displayed on screen":
      "Announcements with matching tags will be displayed on screen",
    "Hardcoded playlists and configurations are being depreciated, please use virtual devices.":
      "Hardcoded playlists and configurations are being depreciated, please use virtual devices.",
    "Just like hardcoded playlists and configurations, a virtual device will provide you with a URL to be used with systems which do not support the QR setup.":
      "Just like hardcoded playlists and configurations, a virtual device will provide you with a URL to be used with systems which do not support the QR setup.",
    "With a virtual device, you will be able to manage the active playlist or configuration without needing to update the third party system.":
      "With a virtual device, you will be able to manage the active playlist or configuration without needing to update the third party system.",
  },
  PlaylistPicker: {
    Playlist: "Playlist",
    "Clear playlist": "Clear playlist",
    "No playlist selected": "No playlist selected",
    "Searching playlists": "Searching playlists",
    "No playlists found for '{{searchText}}'":
      "No playlists found for '{{searchText}}'",
    "No playlists found": "No playlists found",
  },
  TableSearchField: {
    "Clear search text": "Clear search text",
  },
  SettingsScreen: {
    "Organisation settings": "Organisation settings",
    "Permission groups": "Permission groups",
    "User access": "User access",
    "IP Addresses": "IP Addresses",
    "Loading settings": "Loading settings",
  },
  PermissionGroupsTab: {
    "New permission group": "New permission group",
    "Permission groups allow you to specify which hub profiles a user has access to when setting up a schedule":
      "Permission groups allow you to specify which hub profiles a user has access to when setting up a schedule",
    "{{count}} Permission group": "{{count}} Permission group",
    "{{count}} Permission group_other": "{{count}} Permission groups",
    "Search permission groups...": "Search permission groups...",
    Name: "Name",
    "Last updated": "Last updated",
    "Loading permission groups": "Loading permission groups",
    "No permission groups yet": "No permission groups yet",
    "No permission groups found for '{{searchText}}'":
      "No permission groups found for '{{searchText}}'",
    "Permission groups allow you set what hub profiles a user has access to":
      "Permission groups allow you set what hub profiles a user has access to",
    "{{count}} hub profile": "{{count}} hub profile",
    "{{count}} hub profile_other": "{{count}} hub profiles",
    "Permission group created": "Permission group created",
    "Something went wrong": "Something went wrong",
    "Loading permission group": "Loading permission group",
    "Delete permission group": "Delete permission group",
    "Are you sure you want to delete this permission group?":
      "Are you sure you want to delete this permission group?",
    "Permission group deleted": "Permission group deleted",
    "Permission group updated": "Permission group updated",
    "Save changes": "Save changes",
    "Permission group name": "Permission group name",
    "Permitted hub profiles": "Permitted  hub profiles",
    "Only permitted profiles will appear for users to select when managing display schedules":
      "Only Permitted profiles will appear for users to select when managing display schedules",
    "Hub access": "Hub access",
    "Nutrition access": "Nutrition access",
    Hub: "Hub",
    Nutrition: "Nutrition",
    "Accessible apps": "Accessible apps",
  },
  UsersTab: {
    "Contact Teamworks support to add or remove users":
      "Contact Teamworks support to add or remove users",
    "New user": "New user",
    "This is a list of users who have access to Digital Displays":
      "This is a list of users who have access to Digital Displays",
    "{{count}} User": "{{count}} User",
    "{{count}} User_other": "{{count}} Users",
    "Search users...": "Search users...",
    Name: "Name",
    "Last updated": "Last updated",
    "Loading users": "Loading users",
    "Loading user": "Loading user",
    "No users yet": "No users yet",
    "No users found for '{{searchText}}'":
      "No users found for '{{searchText}}'",
    "Super admin": "Super admin",
    "{{count}} permission group": "{{count}} permission group",
    "{{count}} permission group_other": "{{count}} permission groups",
    "User updated": "User updated",
    "Something went wrong": "Something went wrong",
    "Save changes": "Save changes",
    superadmin_description:
      "This user is a super admin. They have access to all hub profiles regardless of their assigned permission groups.",
    admin_description:
      "This user only has access to hub profiles assigned to them via permission groups.",
    "%future added value_description": "Unknown role",
  },
  ManageIpAddressesTab: {
    "Edit IP addresses": "Edit IP addresses",
    "For systems which do not support our QR setup, these permitted IP addresses can be used to allow you access to hardcoded URLs.":
      "For systems which do not support our QR setup, these permitted IP addresses can be used to allow you access to hardcoded URLs.",
    "{{count}} Permitted IP address": "{{count}} Permitted IP address",
    "{{count}} Permitted IP address_other": "{{count}} Permitted IP addresses",
    "No IP addresses": "No IP addresses",
    "You have not added any permitted IP addresses yet":
      "You have not added any permitted IP addresses yet",
    "Permitted IP addresses updated": "Permitted IP addresses updated",
    "Something went wrong": "Something went wrong",
    "Save changes": "Save changes",
    "No permitted IPs yet": "No permitted IPs yet",
    "Add another": "Add another",
  },
  PermissionGroupPicker: {
    "Add permission groups": "Add permission groups",
    "No permission groups added": "No permission groups added",
    "Show {{count}} more": "Show {{count}} more",
    "Clear search text": "Clear search text",
    "Search permission groups...": "Search permission groups...",
    "Loading permission groups": "Loading permission groups",
    "{{count}} hub profile": "{{count}} hub profile",
    "{{count}} hub profile_other": "{{count}} hub profiles",
    "Remove permission group": "Remove permission group",
    "No users found": "No users found",
    "We couldn't find any users matching '{{searchText}}'":
      "We couldn't find any users matching '{{searchText}}'",
    "No permission groups found": "No permission groups found",
    "Confirm selection": "Confirm selection",
  },
  TableProvider: {
    "{{count}} Selected": "{{count}} Selected",
  },
  ComponentPicker: {
    "Pick a component": "Pick a component",
    media: "Media",
    schedule: "Schedule",
    cards: "Cards",
    multi_day_schedule: "Multi day schedule",
    multi_column_schedule: "Multi column schedule",
    nutrition_dining_station: "Nutrition dining station",
    nutrition_menu: "Nutrition menu",
    weather: "Weather",
    "%future added value": "Unknown component",
    Add: "Add",
  },
  TagPicker: {
    "Add tags": "Add tags",
    Clear: "Clear",
    "Searching tags": "Searching tags",
    "No tags selected": "No tags selected",
    "Remove tag": "Remove tag",
    "No tags found matching '{{searchText}}'":
      "No tags found matching '{{searchText}}'",
    "Something went wrong": "Something went wrong",
    "Create tag '{{tagName}}'": "Create tag '{{tagName}}'",
    "Start typing to create a new tag": "Start typing to create a new tag",
    "Delete tag": "Delete tag",
    "Are you sure you want to delete the tag '{{tagName}}'?":
      "Are you sure you want to delete the tag '{{tagName}}'?",
    "Tag deleted": "Tag deleted",
  },
  DeviceScheduleScreen: {
    Devices: "Devices",
    "Device schedule": "Device schedule",
    "Schedule item created": "Schedule item created",
    "Something went wrong": "Something went wrong",
    "Please correct any errors and try again":
      "Please correct any errors and try again",
    "Create schedule item": "Create schedule item",
    "Schedule item updated": "Schedule item updated",
    "Update schedule item": "Update schedule item",
    "End time should be after the start time":
      "End time should be after the start time",
    "Schedule item cannot be in the past":
      "Schedule item cannot be in the past",
    "Starts at": "Starts at",
    "Ends at": "Ends at",
    "Add content": "Add content",
    "Loading schedule": "Loading schedule",
    "Delete schedule item": "Delete schedule item",
    "Are you sure you want to delete this schedule item?":
      "Are you sure you want to delete this schedule item?",
    "Schedule item deleted": "Schedule item deleted",
    "Saving this event will shorten or replace {{count}} item. Are you sure you want to continue?":
      "Saving this event will shorten or replace {{count}} item. Are you sure you want to continue?",
    "Saving this event will shorten or replace {{count}} item. Are you sure you want to continue?_other":
      "Saving this event will shorten or replace {{count}} items. Are you sure you want to continue?",
    "Conflicts found": "Conflicts found",
  },
  Calendar: {
    Previous: "Previous",
    Today: "Today",
    Next: "Next",
    View: "View",
    month: "Month",
    week: "Week",
    work_week: "Work week",
    day: "Day",
    agenda: "Agenda",
  },
  ScheduleConflictChecker: {
    "Checking for conflicts": "Checking for conflicts",
    "No conflicts found": "No conflicts found",
    "{{count}} conflict found": "{{count}} conflict found",
    "{{count}} conflict found_other": "{{count}} conflicts found",
    "Conflicts found": "Conflicts found",
  },
  LocationPicker: {
    "Search locations": "Search locations",
    "Clear search text": "Clear search text",
    "Searching locations": "Searching locations",
    "No results": "No results",
  },
  NutritionMenuField: {
    "Please select a menu": "Please select a menu",
    "Include images": "Include images",
    "Include descriptions": "Include descriptions",
    "Include macros": "Include macros",
    "Unknown menu": "Unknown menu",
    "Please select a dining station": "Please select a dining station",
    "Unknown dining station": "Unknown dining station",
  },
  NutritionMenuPicker: {
    "Must be later than the 'after' time":
      "Must be later than the 'after' time",
    After: "After",
    Before: "Before",
    "Search menus": "Search menus",
    "Nutrition menu": "Nutrition menu",
    "Remove menu": "Remove menu",
    "Searching menus": "Searching menus",
    "{{count}} menu found": "{{count}} menu found",
    "{{count}} menu found_other": "{{count}} menus found",
    "No menus found": "No menus found",
    "Try changing the date range and search again":
      "Try changing the date range and search again",
    "Dining station": "Dining station",
    "Loading stations": "Loading stations",
    "Failed to fetch menus": "Failed to fetch menus",
    "Something went wrong, please try again":
      "Something went wrong, please try again",
    "Failed to fetch menu dining stations":
      "Failed to fetch menu dining stations",
  },
  WeatherFormField: {
    "Number of days ahead to fetch weather. Max 7":
      "Number of days ahead to fetch weather. Max 7",
    "Number of days": "Number of days",
    "Add location": "Add location",
    "Remove location": "Remove location",
    "Are you sure you want to remove this location?":
      "Are you sure you want to remove this location?",
    Date: "Date",
    "Default location": "Default location",
    Location: "Location",
    "Below, you can add different locations for certain dates, which will override the default location for those days.":
      "Below, you can add different locations for certain dates, which will override the default location for those days.",
    "This is the default location for displaying weather, unless overridden by another location on a given date.":
      "This is the default location for displaying weather, unless overridden by another location on a given date.",
  },
  ScreenConfigFrom: {
    "Dates must be unique": "Dates must be unique",
    "Card text must be at least 4 characters long":
      "Card text must be at least 4 characters long",
  },
};

export type Localisation = typeof english;
export type Namespace = keyof Localisation;
